<template></template>

<script setup>
import {getCookie, refreshContextToken, setCookie} from "@shopware/api/index.js";
  import {onMounted} from "vue";

  const props = defineProps({
    setCookies: {
      type: Object,
    }
  });

  onMounted(() => {
    const lastUpdated = getCookie('sw-context-last-updated');
    const contextCookie = getCookie('sw-context-token');

    if (!lastUpdated || lastUpdated < ((Date.now() / 1000) - 3600)) {
      refreshContextToken(contextCookie ?? null);
    }

    if (props.setCookies) {
      for (const key of Object.keys(props.setCookies)) {
        setCookie(key, props.setCookies[key]);
      }
    }
  });
</script>